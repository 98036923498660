@import '/src/assets/scss/var.scss';

.schedule-compare-v3 {
    .sch-summary-table {

        border-collapse: separate;

        thead {
            border-radius: 4px 4px 0px 0px;
            overflow: hidden;

            th {
                color: #888;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 7px 5px;
                background: #F7F6F4;
            }

        }

        td {
            border: 0.5px solid rgba(59, 59, 59, 0.20);
            background: rgba(59, 59, 59, 0.02);
            // padding: 5px;
        }

        .cell-with-padding,
        .button-with-padding {
            padding: 5px;
            color: #3b3b3b;

        }
    }

    .impactBoxHeader {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        padding: 6px 12px;
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: #eee;
        border-top: 1px solid #ddd;
    }

    .impactBoxContainerTitle {
        padding: 0px 0px;
        top: 34px;
        z-index: 9;
    }

    .impactBoxContainerContent {
        padding: 12px;
        padding-top: 0;
        border-bottom: 1px solid #ddd;
    }

    .collapsePanel :global(.ant-collapse-content-box) {
        padding: 0 !important;
    }

    .ant-collapse-content>.ant-collapse-content-box {
        padding: 0 !important;
    }

    .impactingActivity{
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        padding: 6px 0px 0px;
        position: sticky;
        top: 0;
        z-index: 10;
        // background-color: #eee;
        // border-bottom: 1px solid #efefef;
    }

    .impactedEntityBoxHeader {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        padding: 6px 12px;
        margin-bottom: 5px;
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: #eee;
        border-bottom: 1px solid #efefef;
    }

}