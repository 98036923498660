@import '../../assets/scss/var.scss';

.date-block-component {
   color: rgba($color: #000000, $alpha: 0.85);

   .linked-schedule-submittal {
      @apply bg-blue-200 #{!important};
   }

   .disable-ag-grid-row {
      @apply bg-gray-50 text-gray-500 #{!important};
   }

   .bl-circle {
      @apply rounded-[70%] w-6 h-6 border border-solid relative shrink-0;
   }

   .bl-line {
      @apply w-12 border-0 border-t border-solid relative shrink-0 grow
   }

   .bl-line-left {
      @apply w-12 ml-4 border-0 border-t border-solid relative shrink-0 grow;
   }

   .bl-rect {
      @apply w-12 h-4 border border-solid relative shrink-0;
   }

   .bl-above-text-div {
      @apply absolute -top-20 -left-10 w-32 font-semibold flex-col flex text-xs text-center h-16 justify-end;
   }

   .bl-card {
      @apply absolute -left-16 top-8 w-[148px] text-sm flex-wrap flex flex-col justify-evenly border-gray-400;
   }

   .bl-card .ant-card-body {
      @apply w-full h-[130px] p-0 px-2.5 grow;
   }

   .bl-card .ant-card-head {
      @apply h-14 border-b-gray-300 w-full;
   }

   .bl-card .ant-card-head-title {
      @apply whitespace-normal;
   }

   .date-block-governing-box {
      @apply absolute h-[30px] w-[144px] top-[225px] border border-solid border-gray-400 -left-16;
   }

   .bg-delay {
      @apply bg-[#EBEBEB] text-[#3B3B3B] text-opacity-80;
   }

   .card-header {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #3B3B3B;
      @apply p-2 border-solid border-0 border-b border-gray-400 h-[52px] flex justify-between items-center relative;
   }

   .date-block-on-track-circle {
      background-color: $date-block-on-track-bg-color;
   }

   .date-block-delayed-circle {
      background-color: $date-block-delay-bg-color;
   }

   .next-milestone-on-track-border {
      border-color: $date-block-on-track-bg-color;
      border-width: 3px;
   }

   .next-milestone-delay-border {
      border-color: $date-block-delay-bg-color;
      border-width: 3px;
   }

   .next-milestone-on-track-text {
      color: $date-block-milestone-title-color !important;
      background-color: $date-block-on-track-bg-color !important;
      border-bottom-width: 0;
   }

   .next-milestone-delay-text {
      color: $date-block-milestone-title-color !important;
      background-color: $date-block-delay-bg-color !important;
      border-bottom-width: 0;
   }

   .duration-box {
      width: 136px;

      .duration-value {
         padding-right: 20px;
      }
   }

   .date-block-size {
      width: 148px;
      @apply h-48;
   }
}

.date-block-risk-high {
   color: $date-block-risk-highlight-color;
}

.date-block-date-risk {
   color: $date-block-risk-highlight-color;
   font-style: italic;
}

.assessment-text-color {
   color: rgb(238 75 43 / 80%);
}