.listing-window {
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 4px 0px #3B3B3B26;
    position: absolute;
    top: 0;
    left: 0;

    &--nav {
        height: 40px;
        background-color: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #3B3B3B33;
        h5 {
            font-size: 14px;    
            font-weight: 600;
            margin-bottom: 0;
        }
    }
    &--target {
        cursor: grab;
        &.dragging {
            cursor: grabbing !important;
        } 
    }
    &--children {
        height: calc(100% - 40px);
       // overflow: auto;
    }

    &--suggestedLbl {
        font-family: Inter;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #3B3B3BCC;
    }
    
}