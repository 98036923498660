.btnNewSubmittal {
  margin-left: "100px";
  display: "flex";
}

.discriptionColumn {
  margin-top: 15px !important;
}

.discriptionArea {
  color: "black";
  width: "100%";
  background: "#DCDCDC";
}

.duebyCol {
  margin-top: 15px !important;
}

.dueBy {
  width: 100% !important;
}

.drawerDatePicker {
  color: "black";
  width: "60%";
  background: "#DCDCDC";
}

.companyCol {
  margin-top: 15px !important;
}
.constructionSelect {
  width: 100% !important;
}
.assignedCol {
  margin-top: 15px !important;
}
.assignedSelect {
  width: 100% !important;
}
.packageCol {
  margin-top: 15px !important;
}
.statusSelect {
  width: 100% !important;
}
.dependsOnCol {
  margin-top: 15px !important;
}
.dependsOnSelect {
  width: 100% !important;
}
.drawertwoButtons {
  margin-top: 15px !important;
}
.attachementsCol {
  margin-top: 15px !important;
}
.suggestedCol {
  margin-top: 15px !important;
}
#outerBox {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 25px;
}
.innerBox {
  display: inline-block !important;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
  font-weight: 590 !important;
  font: normal normal normal 14px/17px Inter;
  color: black;
}


.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border: 1px solid #ff4d4f;
}

.NewBtnForOpenMOdal {
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: 40%;
}

.text-red-small {
  color: red;
  padding-left: 4%;
  font-size: 12px;
  display: inline;
}
