.todayLabel {
}

.todayLabelLate {
  color: red;
}

.todayIndicator {
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(45deg);
  fill: dodgerblue;
  stroke: darkblue;
}

.todayIndicatorLate {
  fill: red;
  stroke: brown;
}

.milestoneRectangle {
  fill: white;
  stroke: black;
}

.floatRectangle {
  fill: rgba(0, 0, 0, 0.1);
  stroke: none;
}

.floatRectangleExhausted {
  fill: rgba(255, 00, 0, 0.3);
  stroke: none;
}

.floatLabel {
}

.floatLabelExhausted {
  font-weight: 600;
  font-style: italic;
}

.milestoneLabelWrapper {
  font-size: 11px;
  line-height: 1;
}

.milestoneMarker {
  fill: black;
}

.milestoneMarkerLate {
  fill: red;
}

.lastMilestoneMarker {
  fill: dodgerblue;
  width: 3px;
}

.milestoneLabel {
}

.milestoneLabelLate {
}

.arrow {
  stroke: dodgerblue;
  stroke-width: 1px;
}

.arrowHead {
  fill: dodgerblue;
}
