@import './var.scss';
@import './print.scss';

.submittal-listing-grid .ag-header, .material-listing-grid .ag-header, .submittal-insights .ag-header {
    background: $gray;
}

.loading-dots:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}
  
  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: black;
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 black;}}
  