
.submittal-insights {

    .ant-modal-content,
        .rpv-core__inner-page,
        .ant-modal-body {
            @apply bg-gray-50;
            // overflow-y: hidden !important;
        }
    
        .ant-design-draggable-modal-resize-handle {
            width: 22px;
            height: 22px;
        }
    
        .ant-modal-content {
            border: 1px solid rgba(59, 59, 59, 0.50);
            overflow: hidden;
        }
    
        .ant-modal {
            overflow: hidden;
        }
}
