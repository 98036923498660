.depends-on-rowData {
  padding: 1.5% 4%;
  margin-bottom: 2%;
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #000000fa;
  opacity: 1;
  margin-right: 3%;
  font-weight: 600;
}
.depends-on-rowData-active {
  background-color: white;
  padding: 1.5% 4%;
  margin-bottom: 2%;
  margin-right: 3%;
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #000000fa;
  opacity: 1;
  font-weight: 600;
}
.document-section-div {
  overflow-y: auto;
  width: 100%;
}

.upload-wrapper .ant-upload.ant-upload-select {
  display: block !important;
}
