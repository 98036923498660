.schedule-activities-components {
    .ag-cell-wrapper.ag-row-group {
        @apply uppercase font-medium
    }

    .ag-details-row {
        @apply pl-8 pt-2
    }

    .ag-center-cols-container, .ag-center-cols-clipper {
        min-height: 0px !important;
    }
}