.filterRow {
  margin: 10px 0px;
}

.ant-input .ant-input-disabled {
  width: 10%;
}

.grid-cell-centered {
  text-align: center;
}

/* .CardDetails {
  width: "auto";
  border: "0.2px solid #e5e5e5";
} */

.searchInput {
  margin-left: 15px !important;
}

#filter-text-box {
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #0000004d;
  opacity: 1;
  width: 163px;
  height: 20px;
  margin-left: 10px;
}

.StatusInput {
  color: black !important;
  opacity: 0.5 !important;
  background: lightgray !important;
  font-weight: 400 !important;
  width: 40% !important;
  height: 30px !important;
}

.StatusInputSelect {
  width: 100px !important;
  height: 30px !important;
  background: lightgray !important;
  opacity: 1 !important;
  color: black !important;
  font-weight: 700 !important;
}

.CompanyInput {
  width: 55% !important;
  height: 30px !important;
  color: black !important;
  opacity: 0.5 !important;
  background: lightgray !important;
  font-weight: 400 !important;
}

.CompanyInputInputSelect {
  font-weight: 600;
  width: 70px !important;
  height: 30px !important;
  display: flex;
  opacity: 1 !important;
  color: black !important;
  font-weight: 700 !important;
}

.DueInput {
  width: 40% !important;
  height: 40% !important;
  color: black !important;
  opacity: 0.5 !important;
  background: lightgray !important;
  font-weight: 400 !important;
}

.DueInputSelect {
  width: 100px !important;
  height: 30px !important;
  display: flex;
  opacity: 1 !important;
  color: black !important;
  font-weight: 700 !important;
}

.DependsOnInput {
  width: 58% !important;
  height: 30px !important;
  color: black !important;
  opacity: 0.5 !important;
  background: lightgray !important;
  font-weight: 400 !important;
}

.DependsOnInputSelect {
  height: 30px !important;
  width: 60px !important;
  opacity: 1 !important;
  color: black !important;
  font-weight: 700 !important;
  display: flex;
}

.AssignedInputSelect {
  width: 80px !important;
  height: 30px !important;
  display: flex;
  color: black !important;
  font-weight: 700 !important;
  background: lightgray !important;
}

.AssignedInput {
  width: 50% !important;
  height: 30px !important;
  color: black !important;
  opacity: 0.5 !important;
  background: lightgray !important;
  font-weight: 400 !important;
}

#outer {
  width: 100%;
  text-align: end;
}

.inner {
  display: inline-block;
  height: 30px;
}

.EditBtn {
  margin-left: 10px;
  height: 30px;
}

.newSubmittalBtn {
  margin-left: 10px;
  height: 30px;
  margin-right: 10px;
}

.NweSubmittalBtn {
  margin-left: 10px !important;
}

.dependsOnHoverColor span {
  color: black !important;
  text-align: center;
}

.dependsOnHoverColor span:hover {
  color: #0073cf !important;
  text-decoration: underline;
}

.dependsOnDefaultCellColor span {
  text-align: center !important;
  color: black !important;
  text-decoration: underline;
  text-decoration-style: dashed;
}

.select-custom-dropDown {
  width: fit-content !important;
}

.idHoverColor a {
  color: black !important;
  text-align: center;
}

.idHoverColor:hover a {
  color: #0073cf !important;
  text-decoration: underline;
}

.idCellColor {
  text-align: center !important;
  color: black !important;
  text-decoration: underline;
  text-decoration-style: dashed;
}

.colFirstValue {
  margin-bottom: 0px !important;
  text-align: left;
  letter-spacing: 0px;
  color: #000000fa;
  opacity: 1;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: block;
}

.colSecondValue {
  color: #707070;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: inherit;
  line-height: 17px;
  font-family: Inter;
  letter-spacing: 0px;
  opacity: 1;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: block;
  height: 18px;
  margin-top: -10px;
}

div.ag-rich-select .ag-rich-select-list {
  width: 100%;
  min-width: 200px;
  height: 100% !important;
}

.table-row {
  background-color: white;
  border: none;
  border-top: 1px solid #eee;
}

.ag-full-width-container .table-row {
  border: none;
}

/* staging zone drawer css */
.stagingZoneDrawer .ant-drawer-close {
  position: absolute;
  right: 0px;
  margin: 12px;
}

.stagingZoneDrawer .anticon {
  color: #000000;
}

.stagingZoneDrawer .ant-drawer-title {
  text-align: left;
  font: normal normal medium 16px/21px "Inter";
  letter-spacing: 0px;
  color: #000000fa;
  opacity: 1;
}

.stagingZoneDrawer .ant-drawer-body {
  padding: 0px 24px;
  overflow: hidden;
}

.private-org {
  background: #808080 0% 0% no-repeat padding-box;
  border-radius: 2px;
  text-align: left;
  font: normal normal normal 10px "Inter";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 0px 10px;
}

.stagingZoneDrawer .ant-drawer-wrapper-body {
  border: 1px solid black;
  background-color: #eaeae8;
  background-image: repeating-linear-gradient(-45deg,
      transparent,
      transparent 12px,
      #d1d1cf 15px);
}

.expand-icon-right {
  position: absolute;
  top: 1%;
  right: 1.7%;
}

.expand-icon-right-with-text {
  position: absolute;
  top: 2%;
  right: 1.7%;
  text-align: right;
  font: normal normal normal 14px/17px "Inter";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.expand-icon-right:hover {
  cursor: pointer;
}

.stagingZoneDrawer .anticon-close {
  display: none;
}

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: initial;
}

.EditSubmittalbtn {
  padding-right: 1%;
}

.insights {
  position: absolute;
  top: 3px;
  right: 0;
  line-height: 14px;
}

.insight-summary {
  padding-left: 6px;
}

.insights-details-link {
  padding-left: 20px;
}

.text-red-font {
  color: red;
  font-size: 16px;
  text-align: left;
  font: normal normal normal Inter;
  letter-spacing: 0px;
  color: #ff3535;
  opacity: 1;
}

.submittal-list-page .ag-cell-focus,
.ag-cell {
  border: none !important;
}