.input-disabled {
  background-color: #fff !important;
}

.company-form {
  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item.mb-0 {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    padding-bottom: 0;
  }

  .ant-card-body label {
    font-size: 13px;
  }

  .form-item-label {
    height: auto;
    text-transform: uppercase;
    font-size: 0.85em;
    letter-spacing: 0.05em;
    color: #555;
  }
}
