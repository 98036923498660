.ant-scrolling-effect {
    overflow: auto !important;
    width: 100% !important;
}


.file-viewer-modal {

    .ant-modal-header {
        background: #F7F6F4;
    }

    .ant-modal-content,
    .rpv-core__inner-page,
    .ant-modal-body {
        @apply bg-gray-100;
        // overflow-y: hidden !important;
    }

    .ant-design-draggable-modal-resize-handle {
        width: 22px;
        height: 22px;
    }

    .ant-modal-content {
        border: 1px solid rgba(59, 59, 59, 0.50);
        overflow: hidden;
        padding-bottom: 20px;
    }

    .ant-modal {
        overflow: hidden;
    }

}