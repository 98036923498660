.activity-linking {
    position: relative;

    &--nav {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #F5F5F5;
        height: 40px;
        border-bottom: 1px solid #3B3B3B33;
        border-top: 1px solid #3B3B3B33;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 6px;

        h4 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    &--option {
        padding: 10px 6px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-bottom: 1px solid #3B3B3B33;

        i {
            opacity: 0.6;
            margin-left: 6px;
        }
    }

    &--item {
        padding: 8px 5px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #3B3B3B33;
        margin: 6px;
        font-weight: 400;

        i {
            opacity: 0.6;
            margin-left: 6px;
        }

    }
}