.discriptionArea {
  color: "black";
  width: "100%";
  background: "##DCDCDC";
}

.drawerDatePicker {
  width: 100%;
  background: #f2f2f2;
  opacity: 1;
  height: 38px;
  border: none;
}
.submittal-create .select-box {
  background: #f2f2f2;
  opacity: 1;
  height: 38px;
  border: none !important;
}

.submittal-create .ant-form-item-label > label {
  height: 17px;
  text-transform: uppercase;
  color: #00000080;
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0px;
}