.button-full-screen {
  height: 75vh;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.btn-left-outline {
  height: 7%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #808080;
  color: white;
}
.document-not-selected {
  background: #0000000d 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00000055;
  text-align: center;
}
