
.calendar-page {

    .ant-checkbox-disabled + span {
        color: rgba(0, 0, 0, 0.70);
        cursor: not-allowed;
    }

    .ant-form label {
        font-size: 14px;
         width: 190px; 
    }
    
    .ant-form-item-label {
        font-weight: 500;
        padding: 0 0 0px;
        text-align: left;
    }
    .ant-picker-input > input {
        text-align: center;
    }
    .ant-picker-input > input[disabled] {
        color: rgba(0, 0, 0, 0.70);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
        cursor: not-allowed;
        opacity: 1;
    }

    .ant-picker.ant-picker-disabled {
        color: rgba(0, 0, 0, 0.70);
        background: #f5f5f5;
        border-color: rgb(217, 217, 217, 0.25);
        cursor: not-allowed;
    }

    .ant-input[disabled] {
        color: rgba(0, 0, 0, 0.70);
        background-color: #f5f5f5;
        border-color: rgb(217, 217, 217, 0.25);
        box-shadow: none;
        cursor: not-allowed;
        opacity: 1;
    }

    .heading-title {
        @apply font-medium uppercase py-2
    }
    
}