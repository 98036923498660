
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  background: #f7f6f4 0% 0% no-repeat padding-box;
}

.headerMain {
  width: 100%;
  background: #585fa9;
  box-shadow: 0px 2px 20px #00000029;
  opacity: 1;
  height: 50px;
  text-align: center;
  color: white;
  height: 60px;
  font-family: Arial;
}

.logo {
  margin-top: 10px;
}

.ant-avatar>img {
  margin-right: 15px;
  margin-bottom: 5px;
}

.login-form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 10% 0 36px 0;
  padding: 2%;

  box-shadow: 0px 5px 25px #0000001a;
  border-radius: 5px;
  opacity: 1;
  width: 400px;
}

.login-logo h3.ant-typography {
  text-align: center;
  text-transform: capitalize;
}

.login-form-button.ant-btn {
  width: 100%;
  background-color: #585fa9;
  color: #ffffff;
  margin-top: 20px;
}

.ant-form-item.password {
  margin-bottom: 0 10px;
}

.password .ant-row {
  margin-top: 20px !important;
}

.password .ant-input-affix-wrapper {
  border: 1px solid #d9d9d9 !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide>div {
  @apply h-full flex items-center;
}