.feature-activity-card {
    .ant-card-body {
        padding: 0 !important;
    }

    .ant-tabs, .ant-tabs-content, .ant-tabs-tabpane {
        height: 100%;
    }

    .ant-tabs-nav {
        padding: 0px 10px;
        background-color: #F7F7F7;
        border-bottom: 1px solid rgba(59, 59, 59, 0.50);
    }

    .ant-tabs-content-holder {
        padding: 0 !important;
    }

}