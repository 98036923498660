@import '../../../assets/scss/var.scss';

.entity-date-block {
    color: rgba($color: #000000, $alpha: 0.85);

    .bl-circle {
        @apply rounded-[70%] w-6 h-6 border border-solid relative shrink-0;
    }

    .bl-line {
        @apply border-0 border-t border-solid relative shrink-0
    }

    .bl-rect {
        @apply w-12 h-4 border border-solid relative shrink-0;
    }

    .card-header {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #3B3B3B;
        @apply p-2 border-solid border-0 border-b border-gray-400 h-[52px] flex justify-between items-center relative;
    }

    .bg-type-1 {
        background-color: rgba(242, 242, 242, 0.8);
    }

    .date-block-on-track-circle {
        background-color: $date-block-on-track-bg-color;
    }

    .date-block-delayed-circle {
        background-color: $date-block-delay-bg-color;
    }

    .next-milestone-on-track-border {
        border-color: $date-block-on-track-bg-color;
        border-width: 2px;
    }

    .next-milestone-delay-border {
        border-color: $date-block-delay-bg-color;
        border-width: 2px;
    }

    .next-milestone-on-track-text {
        color: $date-block-milestone-title-color !important;
        background-color: $date-block-on-track-bg-color !important;
        border-bottom-width: 0;
    }

    .next-milestone-delay-text {
        color: $date-block-milestone-title-color !important;
        background-color: $date-block-delay-bg-color !important;
        border-bottom-width: 0;
    }

    .ellipsis-clamp-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bg-delay {
        @apply bg-[#EBEBEB] text-[#3B3B3B] text-opacity-80;
    }

}