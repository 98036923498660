.linking {
    &-page {
        background-color: rgb(255, 255, 255);

        &::-webkit-scrollbar {
            width: 2px;
        }
    }

    &-select-empty {
        width: 425px;
    }

    &-border {
        border: 1px solid #D9D9D9;
    }

    &-container {
        .ant-checkbox-disabled+span {
            color: rgba(0, 0, 0, 0.7) !important;
        }
        &--empty {
            border: 1px solid #3B3B3B33;

            & .linking-container--nav {
                border: 0px none #3B3B3B33;
                border-bottom: 1px solid #3B3B3B33;
            }
        }

        &--nav {
            height: 50px;
            border: 1px solid #3B3B3B33;
            border-bottom: 0px none #3B3B3B33;
            display: flex;
            align-items: center;
            padding: 10px 6px;

            & i {
                opacity: 0.6;
            }

            &__icon {
                padding: 0;

                h3 {
                    color: #42a3fd !important;
                }

                svg {
                    fill: #42a3fd !important;
                }

                &:hover {
                    border-color: transparent !important;
                }
            }

            h3 {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
            }

            &__select {
                &>div {
                    justify-content: space-between;
                }
            }

            &__dropdown {
                max-height: 300px;
                overflow-y: auto;
                background-color: rgb(255, 255, 255);
                border: 1px solid #3B3B3B33;

                & .ant-space-item {
                    flex-grow: 1;
                }
                & .ant-input-search .ant-input {
                    height: 32px !important;
                }
                & .ant-input-group-addon {
                    left: 0 !important;
                }

                &--selected {
                    display: flex;
                    justify-content: space-between;

                    &>span {
                        overflow: hidden;
                        width: 400px;
                        text-overflow: ellipsis;
                        text-align: left;
                    }
                }
            }

            &__option {
                padding: 10px;
                border-bottom: 1px solid #3B3B3B33;
                font-size: 14px;
                cursor: pointer;

                &>span {
                    width: 400px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                & i {
                    opacity: 0.6;
                }

                &:hover {
                    background-color: #f7f6f4;
                }
            }

            &__loading {
                min-width: 200px;
                padding: 10px;
                background-color: rgb(255, 255, 255);
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 0 4px #3B3B3B33;
            }
            &__redirectlinkIcon{
                width: 35px;
                height: 32px;
                top: 199px;
                left: 626px;
                justify-content: center;
                align-items: center;
                border: 1px solid #3b3b3b34;
                border-radius: 2px;
                &:hover {
                    border: 1px solid #3b3b3bde;
                }
                
            }
        }
    }

    &-group {
        display: grid;
        grid-template-columns: 45% 55%;
        height: calc(100vh - 158px);
        border: 1px solid #3B3B3B33;

        &--left {
            border-right: 1px solid #3B3B3B33;
            height: 100%;
            overflow: hidden;

            &>div {
                height: calc(100% - 36px);
              //  overflow: auto;
            }

            &.show-material>div {
                height: calc(50% - 18px);
               // overflow: auto;
            }
        }

        &--right {
            height: 100%;
            overflow: hidden;
            position: relative;

            &--nav {
                height: 40px;
                display: flex;
                align-items: center;
                padding: 10px;
                background: #F5F5F5;
                border-bottom: 1px solid #3B3B3B33;

                h2 {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 0;
                }
            }

            &--content {
                margin: 10px 6px;

                &__instruction {
                    font-size: 14px;
                    line-height: 1.5;
                    margin-bottom: 0;
                    padding: 0 8px;
                }
            }
        }
    }

    &-search {
        position: sticky;
        top: 0px;
        z-index: 1;

        & input {
            background-color: #fff;
        }

        & button {
            background-color: #fff;
        }
    }

    &--unlink-button {
        padding: 0px;

        &:hover,
        &:focus,
        &:active {
            border-color: transparent !important;
            color: #1890ff !important;
            border-bottom-color: #1890ff !important;
        }
    }

    &-loading--container {
        height: calc(100vh - 200px);
        display: flex;
        align-items: center;
        justify-content: center;

        &>div {
            padding: 4px 10px;
            box-shadow: 0 0 4px #3B3B3B33;
            border: 1px solid #3B3B3B33;
        }
    }

    &-track-submittal {
        border-top: 1px solid rgba(59, 59, 59, 0.2);
    }

    &-redirection {
        &:hover {
            color: #1890ff;
            border-bottom: 1px solid dotted;
        }
    }

    &-date-block {
        height: 400px;
        padding: 10px;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        border: 1px solid #3B3B3B33;
        margin-top: 10px;

        &--submittal {
            &__title {
                font-size: 14px;
                font-weight: 600;
                padding-left: 10px;
            }

            &__container {
                padding-top: 80px;
            }
        }

        &--material {
            &__title {
                font-size: 14px;
                font-weight: 600;
                padding-left: 10px;
            }

            &__container {
                padding-top: 80px;
            }
        }

        &--divider {
            padding-top: 108px;
            min-width: 120px;

            &__vertical {
                height: 100px;
                transform: translate(30%, -50%);
            }
        }
    }
}

.popoverNoPadding {
    .ant-popover-inner-content {
        padding: 0px !important;
    }
}