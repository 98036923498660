.cell-edit-icon,
.spec-file-icon {
  opacity: 0;
  display: none;
}

.ag-row-even:hover .cell-editable .cell-edit-icon,
.ag-row-odd:hover .cell-editable .cell-edit-icon {
  opacity: 0.3;
  display: inline;
}

.ag-row-even:hover .cell-editable:hover .cell-edit-icon,
.ag-row-odd:hover .cell-editable:hover .cell-edit-icon {
  opacity: 1;
  cursor: pointer;
}

.ag-row-even:hover .spec-file-icon,
.ag-row-odd:hover .spec-file-icon {
  opacity: 0.3;
}

.ag-row-even:hover .spec-file-icon,
.ag-row-odd:hover .spec-file-icon {
  opacity: 1;
  cursor: pointer;
}

.info-icon {
  margin-left: 5px;
}

.risk-high-box {
  border: 1px solid #e2381f;
  background: #e2381f1a;
}

.icon-link-arrow {
  color: #3b3b3bcc;
}

.icon-link-arrow:hover {
  border: none;
  color: black;
}

.icon-link-arrow:not(:hover) {
  border: none;
}

.button-spacing {
  margin-left: 5px;
}
