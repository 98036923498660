.ciq-date-picker {
    .ant-picker-suffix {
        display: none;
    }

    .ant-picker-panel-container {
        box-shadow: none;
        margin-top: -30px;
        margin-left: 12px;
        box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.2);
    }
}