.ciq-card-block {
    border: 1px solid rgba(59, 59, 59, 0.50);

    @apply h-full max-h-full;

    &.actionable {
        border: 2px solid rgba(59, 59, 59, 0.80) !important;
    }

    .ant-card-body {
        @apply h-full max-h-full flex flex-col;
    }

}