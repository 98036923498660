
.pencil-icon {
    display: none;
  }
  .ag-row-hover .pencil-icon {
    display: initial;
  }

  .company-edit-icon{
    color: #00000073;
    :hover{
      color: #00000095;
    }
  }