.invite-acc-user {
    .ant-input[disabled] {
        color: rgba(0, 0, 0, 0.70);
        background-color: #f5f5f5;
        border-color: rgb(217, 217, 217, 0.25);
        box-shadow: none;
        cursor: not-allowed;
        opacity: 1;
    }

    .ant-select-selection-item {
        color: #3b3b3b;
        flex: 1 1;
        overflow: hidden;
        font-weight: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}