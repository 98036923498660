@import "~antd/dist/antd.css";

.site-card-wrapper {
  padding: 15px;
  background: #ececec;
}

.submittal-tabs .ant-tabs-content, .details-page-tabs .ant-tabs-content {
  background-color: #ffffff;
  padding: 5px;
}

.reload {
  float: right;
  margin-right: 40px;
  display: flex;
}

.package {
  float: right;
  display: flex;
}

.subDetailsDepndsLabel {
  margin-left: 14px !important;
}

.DependsStatus {
  color: red;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  /* float: right; */
  /* margin-right: 0px; */
  flex-wrap: wrap;
}

.parent {
  /* background: mediumpurple; */
  padding: 1rem;
  margin-left: -38px;
}

.child {
  /* border: 1px solid indigo; */
  padding: 1rem;
}

.inline-block-child {
  display: inline-block;
}

/* .ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
  border: 1px solid black;
  height: 34px;
} */

.subDetailsCardBtn {
  padding: 0px;
  text-align: left;
  /* font: normal normal normal 1.76vh/2.05vh Inter; */
  letter-spacing: 0px;
  color: #000000;
  background: transparent;
  border: none;
  box-shadow: none;
}

.allSubmittalBtnCol {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: inherit;
  /*   border-right: 2px solid #0000000d; */
}

.splitBtnCol {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2px;
}

.SubDetailsParagraph {
  display: flex;
  margin-top: 1.8px;
}

.subDetailsLabel {
  margin-left: 20px !important;
}

.subDetailsStatus {
  width: 200px !important;
  margin-left: 20px !important;
}

.firstRow {
  margin-left: 25px;
  justify-content: space-around;
}

.subDetailsAssigned {
  width: 200px !important;
}

.subDetailsContractor {
  width: 200px !important;
}

.subDetailsArrowOutlined {
  float: right;
  display: flex;
}

.subDetailspackage {
  float: right;
  display: flex;
}

.SubDetailsSecondRow {
  margin-left: 14px;
  margin-top: 20px;
}

.subDetialsDiscription {
  margin-left: 15px !important;
  margin-top: 20px !important;
}

.subDetialsDisArea {
  margin-left: 15px !important;
  max-width: 94% !important;
}

.subDetailsAttachments {
  margin-left: -32px !important;
}

.subDetailsDatePicker {
  width: 200px !important;
}

.subDetailsAttachements {
  border: none;
  padding: 0;
  background: none;
  margin-bottom: 20px;
}

.subDetailsAttachIcon {
  border: none;
  padding: 0;
  background: none;
  margin-left: 90px;
}

.subDetialsThirdRow {
  margin-left: 0px;
  margin-top: 20px;
}

.subDetailsAction {
  color: black;
  font-weight: bold;
  margin-top: 12px;
  font-size: 15px;
}

.subDetailsBlockedBtn {
  border: none;
  padding: 0;
  background: none;
  margin-bottom: 20px;
}

.subDetailsBlockedIcon {
  border: none;
  padding: 0;
  background: none;
  margin-left: 90px;
}

.actionItemCard {
  width: 100%;
  margin-top: 40px;
  padding: 12px;
}

.actionItemsCheckBox {
  font-weight: 600;
  font-size: 13px;
}

.rowActionItems {
  margin-top: 20px;
}

.subDetailsInsightsCard {
  width: 100%;
  margin-top: 12px;
  padding: 12px;
  background-color: white;
}

.subDetailsInsightsTitle {
  color: black;
  font-weight: bold;
  margin-top: 12px;
  font-size: 15px;
}

.subDetailsInsightSummary {
  font-weight: 600;
  font-size: 13px;
}

.subDetailsInsightDescription {
  font-size: 12px;
  font-weight: 500;
}

.submittalDetailsPageIdCol {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 34px;
}

.submittalDetailsPageId {
  text-align: left;
  font: normal normal normal 1.95vh/2.34vh Inter !important;
  letter-spacing: 0px;
  color: #0000007f !important;
  opacity: 1;
  margin-bottom: 0px !important;
}

.subDetailsSplitBtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #007aff;
  opacity: 1;
  font: normal normal normal 1.56vh/1.95vh Inter !important;
  letter-spacing: 0px;
  color: #007aff;
  padding: 0.49vh 1.37vh !important;
  height: 2.93vh;
  margin: 0px;
}

.submittalTitle {
  width: 100%;
  margin-bottom: 0px !important;
  margin-left: 1.56vh;
  text-align: left;
  font: normal normal normal 1.95vh/2.34vh Inter !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}

.submittalTitleCol {
  display: flex;
  align-items: center;
}
.add-new-column-btn {
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #0000007f;
  opacity: 1;
}
.attachments {
  height: 73.5vh;
  /* margin-bottom: 1%; */
}
.materials {
  height: 73.5vh;
}
.discussion {
  height: 73.5vh;
}
.submission {
  height: 73.5vh;
}
.activity {
  height: 73.5vh;
}

.submittalDetailsHeading {
  margin-left: 1.8vw;
  width: 75%;
}

.submittalDetailsHeading > .ant-space-item:last-child {
  width: 100%;
}
