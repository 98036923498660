.users-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name-email {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.users-container p {
  padding: 0;
  margin: 0;
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: #3B3B3B;
  margin-top: 4px;
}

.email {
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B3B3BCC;
}

.avatar-with-badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avator {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: #D9D9D999;
  ;
}

.avator img {
  border-radius: 50%;
  object-fit: cover;
  border-radius: 50%;
}

.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
  font-size: 14px;
  font-weight: 600;
  color: #3B3B3B;
  ;
}