$background-color: #F7F6F4;
$danger-color: #E2381F;
$border-color-1: #C6C5C3;
$border-color-2: #E6E6E6;
$border-color-3: #00000033;

$text-color-1: #3B3B3B;
$text-color-2: #555555D9;

.request-durations-multi {

    .container-bg-color {
        background-color: $background-color !important;
    }

    .ant-collapse {
        background-color: $background-color !important;
    }

    .ant-collapse-header {
        padding: 8px 16px !important;
    }

    .ant-collapse-content-box {
        padding: 0px 12px !important;
    }

    .border-color-1 {
        border-color: $border-color-1;
    }

    .border-color-2 {
        border-color: $border-color-2;
    }

    .border-color-3 {
        border-color: $border-color-3;
    }

    .danger-text {
        color: $danger-color;
    }

    .text-color-1 {
        color: $text-color-1;
    }

    .text-color-2 {
        color: $text-color-2;
    }

    .sub-heading {
        height: auto;
        letter-spacing: 0.05em;
        color: #555;
        font-weight: 590 !important;
        font: normal normal normal 14px / 17px Inter;
        font-size: 0.85em !important;
    }

}