.gc-project-dashboard {
  .ant-btn-link {
    border: none;
    @apply h-fit;
  }
  .ant-btn-link:hover,
  .ant-btn-link:focus {
    border: none;
    color: #000;
    text-shadow: black;
  }

  .box-border {
    border: 1px solid #3b3b3b4d;
  }

  .bottom-border {
    border-bottom: 1px solid #3b3b3b4d;
  }

  .box-header {
    border-bottom: 1px solid #3b3b3b4d;
    color: #00000066;
    background-color: #f7f6f4;
    @apply w-full h-10 flex items-center px-2 uppercase font-semibold;
  }
  .ag-theme-alpine {
    --ag-header-background-color: #f7f6f4;
  }

  .ag-theme-alpine .ag-header-cell-text {
    color: #00000066;
  }

  .alert-message {
    background-color: #3b3b3b26;
    @apply h-8 flex items-center text-xs justify-center;
  }

  .text-matrix-box {
    @apply text-[#3B3B3B] font-medium;
  }
}

.container {
  display: grid;
  grid-template-columns: 10% 15% 12%;
  gap: 20px;
  padding-left: 1rem !important;
}

.project-dashboard-button{
  color: black;
  border: none;
  padding-left: 5px;
  padding-right: 0px;
  text-align: left;
}

.project-dashboard-button:hover {
  color: black;
  border: none;
  padding-left: 5px;
  padding-right: 0px;
  text-align: left;
}

.analytics-dashboard-button{
  color: black;
  border: none;
  padding-left: 0px;
  text-align: left;
}

.analytics-dashboard-button:hover {
  color: black;
  border: none;
  padding-left: 0px;
  text-align: left;
}

.hideScroll {
  overflow-y: auto;
  scrollbar-width: none;
  margin-bottom: 50px;
}
