.workflow-template-editor {
    .bl-circle {
        @apply rounded-[70%] w-9 h-9 border border-solid shrink-0;
     }

   .bl-left-emplty {
      width: 97px;
      @apply shrink-0
   } 
   .bl-line {
      width: 70px;
      @apply border-0 border-t border-solid shrink-0
   }

   .bl-line-left {  
      margin-left: 27px; 
      width: 70px;    
      @apply border-0 border-t border-solid shrink-0;
   }

   .bl-rect {
      width: 100px;
      @apply h-9 border border-solid shrink-0;
   }

   .bl-above-text-div {
      @apply absolute -top-20 -left-9 w-32 font-semibold flex-col flex text-xs text-center h-16 justify-end;
   }

   .text-milestone {
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      height: 20px;
      align-content: center;
      text-align: end;
      max-width: 145px;
      white-space: pre;
   }

   .text-edit-offset {
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      padding: 4px 0px;
   }
   .ant-modal-body {
      padding: 12px !important;
   }

   .ant-form-item {
      margin-bottom: 0px;
   }
   .ant-popover-inner-content {
      padding: 1px;
   }
   .editable-input-form {
      .ant-form-item-explain-error {
         background: white;
         position: absolute;
      }
   }
}