.integration-service-card {
    .element-border-color {
        border-color: rgba(59, 59, 59, 0.20);
    }

    .card-header {
        @apply flex items-center justify-between text-sm uppercase font-semibold px-3 h-[35px] text-neutral-700;
        background-color: rgba(59, 59, 59, 0.10);
        border-bottom: 1px solid rgba(59, 59, 59, 0.20);
    }

    .card-content {
        @apply p-3.5 font-normal border-0 border-b border-solid;
        border-color: rgba(59, 59, 59, 0.20);
        color: rgba(59, 59, 59, 0.70);
    }
}

.configure-integration {
    .info-label {
        color: rgba(59, 59, 59, 0.80);
        @apply text-sm uppercase font-medium;
    }

    .info-value {
        color: rgba(59, 59, 59, 0.80);
        @apply text-base font-medium text-neutral-700;
    }

    .hide-required-mark .ant-form-item-label label::after {
        display: none !important;
    }

    .required-field {
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
    }

    .ant-select-selector {
        .ant-select-selection-overflow-item {
            max-width: 170px;
        }
    }

    .tag-selected-item {
        background: #f5f5f5;
        border: 1px solid #f0f0f0;
        height: 25px;
        border-radius: 2px;
        line-height: 22px;
        padding: 0 10px 0 10px;
        font-size: 14px;
    }

    footer {
        border-color: rgba(59, 59, 59, 0.20);
    }
}

.integration-wizard {
    .pill {
        display: flex;
        align-items: center;
        width: 225px;
        height: 35px;
        position: relative;
        border-radius: 70px;
        font-size: 14px;
        font-weight: 500;

        border: 1px solid #3B3B3B;
        background: rgba(59, 59, 59, 0.40);
        color: #3B3B3B;

        &.completed {
            border: 1px solid #16A34A;
            background: #A2DAB7;
            color: #16A34A;

            .check-mark {
                display: flex;

            }
        }

        &.inprogress {
            border: 1px solid #F59E0B;
            background: #FBD89D;
            ;
            color: #F59E0B;


        }

        .check-mark {
            position: absolute;
            display: none;
            width: 30px;
            height: 30px;
            border-radius: 70px;
            background: #16A34A;
            color: #ffffff;
            font-size: 16px;
            align-items: center;
            justify-content: center;
            right: 0.125rem;
        }


    }

    .pill-connection {
        border: 0;
        border-bottom: 1px solid #3B3B3B;

        &.completed {
            border-bottom: 1px solid #16A34A;
        }

        &.inprogress {
            border-bottom: 1px solid #F59E0B;

        }
    }
}