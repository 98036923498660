.links-card {
    .custom-collapse {
        border: 0;

        .custom-panel {
            margin-bottom: 5px;
            overflow: hidden;
            border: 0px;

            &.ant-collapse-item-disabled {
                .ant-collapse-header {
                    color: inherit !important;
                    cursor: inherit !important;

                    .ant-collapse-expand-icon {
                        display: none;
                    }
                }
            }

            .ant-collapse-header {
                background-color: #ffffff;
                padding: 5px 6px !important;
            }

            .ant-collapse-content-box {
                padding: 8px 6px !important;
                background-color: #ffffff !important;
            }

            .ant-collapse-header {
                border: 1px solid #00000033;

                .ant-collapse-header-text {
                    margin-right: 3px;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

            }

            .ant-collapse-content {
                border: 1px solid #00000033;
                border-top: 0px;
            }

            &.risk-panel {
                border: 1px solid #E2381F !important;
                border-radius: 2px;
            }
        }
    }

    .attachment {
        border: 1px solid rgba(59, 59, 59, 0.2);
        padding: 3px;
        font-size: 0.75rem;
        background: #F1F1F1;
        max-width: 100px;
        color: inherit !important;

    }

    .view-details-link {
        color: #5399EB;
        font-family: Inter;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
        border-bottom: 1px solid #5399EB;
    }

    .material-name-input {
        .ant-input:focus {
            box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
        }
    }

}