.material-linking {
    position: relative;
    border-bottom: 1px solid #3B3B3B33;
    &--nav {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #F5F5F5;
        height: 40px;
        border-bottom: 1px solid #3B3B3B33;
        border-top: 1px solid #3B3B3B33;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 6px;
        h4 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
    &--option {
        padding: 10px 6px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-bottom: 1px solid #3B3B3B33;
        i {
            opacity: 0.6;
        }
    }
    &--item {
        padding: 8px 5px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #3B3B3B33;
        margin: 6px;
        font-weight: 400;
    }
    &--suggestedOption {
        padding: 10px 6px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-bottom: 1px solid #3B3B3B4D;
        background: linear-gradient(0deg, rgba(59, 59, 59, 0.09), rgba(59, 59, 59, 0.09));
        i {
            opacity: 0.6;
        }
    }
    &--suggestedLblDiv {
        padding: 10px 6px;
        border-bottom: 1px solid #3B3B3B4D;
        background: linear-gradient(0deg, rgba(59, 59, 59, 0.09), rgba(59, 59, 59, 0.09));
    }
    &--suggestInfoLbl {
      
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #3B3B3B;
    }
}