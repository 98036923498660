.custom-filter {
    &-bar {
        width: 100px;
        font-size: 12px;
        padding: 1px 10px;
        border-radius: 2px;
        border: 1px solid #ccc;
        background-color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px;
    }
    &-header {
        font-size: 12px;
        border-bottom: 1px solid #3c3c3c3c;
        margin-bottom: 4px;
    }
    &-count {
        padding: 2px 8px;
        border-radius: 1em;
        background-color: #000000;
        color: white;
        display: flex;
        justify-content: center;    
        align-items: center;
        font-size: 10px;
        margin-left: 8px;
    }
    &-options {
        background-color: white;
        max-width: 360px;
        min-width: 200px;
        max-height: 300px;
        overflow-y: auto;
        padding: 10px;
        border-radius: 6px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
        &__container {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &-values {
        max-width: 200px;
        margin: 4px;
        padding: 2px 6px;
        border-radius: 1em;
        border: 1px solid #ccc;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-divider {
        border-top: 1px solid #3c3c3c3c;
        margin-top: 4px;
    }
}