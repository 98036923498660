.delete-project {

    .ant-modal-confirm-body .ant-modal-confirm-content {
        margin-left: 40px;
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
    }

    .ant-modal-confirm-body>.anticon {
        margin-top: 5%;
        float: left;
        margin-right: 16px;
        font-size: 22px;
    }

}