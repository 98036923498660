.spec-docs-tab {
    .ciq-card {
        width: 100%;
        border: 1px solid #f0f0f0;
        background-color: #ffffff;
        border-radius: 2px 2px 0 0;

        .ciq-card-header {
            padding: 8px 12px;
            border-bottom: 1px solid #f0f0f0;
        }

        .ciq-card-content {
            padding: 8px 12px;
        }
    }

    .file-version-item {
        @apply text-sm w-full flex items-center gap-x-1 bg-white border border-transparent p-1.5 px-4 mt-0.5;

        &.highlight-on-hover {
            @apply hover:border-black cursor-pointer
        }

        &.selected {
            @apply border border-black font-semibold
        }
    }

}