.create-project-wrapper {
    .section-separator {
        border: 0.5px solid rgba(0, 0, 0, 0.05);
    }

    .integration-type-section {
        border: 1px solid rgba(51, 51, 51, 0.30);
        background: rgba(51, 51, 51, 0.05);
    }
    .project-template-checkbox-label{
        font-size: 0.85em;
        letter-spacing: 0.05em;
        color: #555;
        vertical-align: middle;
    }
    .source-project-label label{
        font-weight: 500 !important;
    }
}