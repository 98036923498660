.popoverNoPadding {
    padding-top: 0;

    :global(.ant-popover-inner-content) {
        padding: 0;

    }
}

.boxStyle {
    width: 288px;
    color: #3B3B3BCC;
    border-color: #3B3B3B66;
    border-style: solid;
    border-width: thin;


    > :not([hidden])~ :not([hidden]) {
        margin-top: 0.5px;
        margin-bottom: 0.5px;
        border-color: #3B3B3B66;
        border-style: solid;
        border-width: 0px;
        border-top-width: 1px;
        border-bottom-width: 0px;
    }
}