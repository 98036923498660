.gantt-container {
    height: 100%;
    width: 100%;
  }

  .weekend{ background: #f4f7f4 !important;}

  .gantt_task_line{
    border-color: rgba(0, 0, 0, 0.25);
}
.gantt_task_line .gantt_task_progress {
    background-color: rgba(0, 0, 0, 0.25);
}

/* Task */
.gantt_task_line.task {
    background-color: #3B82F6;
}
.gantt_task_line.task .gantt_task_content {
    color: #fff;
}

/* Submittal Task */
.gantt_task_line.submittal_task {
    background-color: #19D486;
}
.gantt_task_line.submittal_task .gantt_task_content {
    color: #fff;
}

/* Submittal Delayed */
.gantt_task_line.delayed_submittal_task {
    background-color: #19D486;
    // box-shadow: 0 0 0 4px rgba(255, 0, 0, 0.3), 0 0 6px 0 rgba(255, 0, 0, 0.5);
}

/* Material Task */
.gantt_task_line.material_task {
    background-color: #203A72;
}
.gantt_task_line.material_task .gantt_task_content {
    color: #fff;
}

//Material delayed
.gantt_task_line.delayed_material_task {
    background-color: #203A72;
    // box-shadow: 0 0 0 4px rgba(255, 0, 0, 0.3), 0 0 6px 0 rgba(255, 0, 0, 0.5);
}

.gantt_task_line {
    z-index: 2;
}

.gantt_row {
    color: #3B3B3B;
 }

.partial-visible-task{
    opacity: 0.2;
}

.unselected-link .gantt_line_wrapper div{
    background-color: #fde4c0;
}

.unselected-link .gantt_line_wrapper {
    z-index: 0;
}

.unselected-link .gantt_link_arrow_right{
    opacity: 0.2;
}
.unselected-link .gantt_link_arrow_left{
    opacity: 0.2;
}

.selected-link .gantt_line_wrapper div{
    opacity: 1.0;
}
.selected-link .gantt_link_arrow_right{
    opacity: 1.0;
}
.selected-link .gantt_link_arrow_left{
    opacity: 1.0;
}
.selected-link .gantt_line_wrapper {
    z-index: 1;
}

 .gantt_grid_data .gantt_row.odd:hover, .gantt_grid_data .gantt_row:hover,
.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected{
    background-color: rgba(247, 245, 244, 1); 
}

.gantt_grid_data, .gantt_task_scale, 
.gantt_task .gantt_task_scale .gantt_scale_cell,
.gantt_grid_scale .gantt_grid_head_cell {
    color: #888;
}

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
    background-color: rgba(20, 184, 166, 0.2);
}
