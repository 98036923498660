.material-details-page {

    .material-details-page-tab {
        .ant-tabs-content-holder {
            background-color: #ffffff;
            padding: 0px 5px;
        }
    }

    .material-details {
        .selectStyle {
            @apply w-full text-left text-[#000000fa];
            font-size: var(--font-size-five) !important;
        }

        .selectStyle.ant-select-disabled .ant-select-selection-item {
            @apply text-gray-700;
        }

        .selectStyle.ant-picker-disabled .ant-picker-input input {
            @apply text-gray-700;
        }

        .selectStyle .ant-picker-input>input {
            font-size: var(--font-size-five) !important;
        }

        .selectStyle,
        .submittal-details input.selectStyle[type="text"] {
            height: 32px;
        }

        [data-submittal="false"] {
            .material-col {
                width: 100%;
            }

            .material-col .material-row {
                width: 100%;
                padding-left: 5rem;
            }
        }

        .add-more-btn {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
            text-decoration: underline;
            text-underline-position: below;
        }

        .card-block,
        .date-block-row {
            border: 1px solid rgba(59, 59, 59, 0.50);
        }
    }

    .material-attachments {
        .attachment-file-item {
            width: 100%;
            background: #ffffff;
            border: 0;
            text-align: left;
            // padding: 8px 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            &:hover,
            &.selected {
                font-weight: bold;
            }
        }
    }

    .no-linking-item-found {
        height: 14rem;
        min-width: 300px;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
    }

    .no-linking-item-found--title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        color: #3b3b3bb1
    }

}