.material-card {
    &--option {
        padding: 10px 6px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-bottom: 1px solid #3B3B3B33;
    }

    &--item {
        padding: 8px 5px;
        font-size: 14px;
        align-items: center;
        border: 1px solid #3B3B3B33;
        margin: 6px;
        font-weight: 400;

        i {
            opacity: 0.6;
            margin-left: 6px;
        }

        &.selected {
            background: #ececec;
        }
    }
}