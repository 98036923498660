/* table styling */
/* https://www.ag-grid.com/react-data-grid/global-style-customisation-variables/ */
.ag-theme-alpine {
  --ag-font-size: 12px;

  --ag-header-background-color: white;
  --ag-header-column-resize-handle-height: 48px;
  --ag-header-column-resize-handle-width: 1px;

  --ag-background-color: white;
  --ag-odd-row-background-color: white;
  --ag-row-hover-color: #f7f6f488;
  --ag-alpine-active-color: black;
  --ag-selected-row-background-color: #f0efed;

  --ag-border-color: #ccc;
  --ag-secondary-border-color: #e5e5e5;

  --ag-cell-horizontal-padding: 12px;
}

.ag-theme-alpine .ag-header-cell-text {
  text-transform: uppercase;
  font-size: 0.95em;
  letter-spacing: 0.08em;
  color: #888;
}

.ag-checkbox-input-wrapper input,
.ag-checkbox-input-wrapper input {
  // -webkit-appearance: checkbox;
  opacity: 100%;
}

.ag-checkbox-input-wrapper:active,
.ag-checkbox-input-wrapper:focus-within {
  box-shadow: none;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  background-color: white;
}

.ag-tab-selected {
  border-color: transparent;
}

/* tabs */
.ant-tabs-tab .ant-tabs-tab-btn {
  color: #000000 !important;
  text-shadow: none !important;
}

.ant-tabs-tab-active {
  font-weight: 600 !important;
}

.ant-tabs-top>.ant-tabs-nav {
  height: 45px;
  margin-bottom: 0px;
}

.ant-tabs-ink-bar {
  background: #000000;
}

/* forms */
.ant-form-item {
  margin-bottom: 12px;
}

.ant-form-item .ant-form-item-label {
  padding-bottom: 0;
}

.ant-form-item .ant-form-item-label label {
  height: auto;
  width: 100%;
  text-transform: uppercase;
  font-size: 0.85em;
  letter-spacing: 0.05em;
  color: #555;
}

.ant-form-item-has-error .ant-input {
  border: 1px solid #ff4d4f;
}

.ant-form-item-explain-error {
  margin-bottom: 12px;
}

/* tooltips */
.ant-tooltip-arrow-content::before {
  background: white;
}

.ant-tooltip-inner {
  background-color: white;
  color: black;
  max-width: 320px;
  margin-left: 0px;
}

.ant-tooltip-inner hr {
  border: 1px solid #eee;
  border-bottom: 0;
}

/* buttons */
.ant-btn:hover,
.ant-btn:focus {
  border-color: #000;
  color: #000;
}

.ant-btn-primary {
  background-color: #333;
  border-color: #000;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #000;
  border-color: #000;
  color: white;
}

/* inputs */
.ant-picker {
  color: #00000040;
}

.ant-input:hover,
.ant-picker:hover {
  border-color: #555;
}

.ant-input:focus {
  border-color: #555;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.ant-picker-focused {
  border-color: #555;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.ant-input-affix-wrapper:hover {
  border-color: #555 !important;
}

.ant-input-affix-wrapper-focused {
  border-color: #555;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

/* radio */
.ant-radio-checked::after {
  border: 1px solid black;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #000;
}

.ant-radio-checked .ant-radio-inner {
  border-color: black;
}

.ant-radio-inner::after {
  background-color: black;
}

.ant-radio-input:focus+.ant-radio-inner {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

/* select */
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #555;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #555;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

/* spinner */
.ant-spin-dot-item {
  background-color: #555;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-typography-edit-content textarea {
  margin-left: 16px !important;
}

.ant-switch-checked {
  @apply bg-gray-600
}

.ant-checkbox-checked .ant-checkbox-inner {
  @apply bg-gray-600 border-gray-800
}

.ant-checkbox:hover .ant-checkbox-inner {
  @apply border-gray-900
}

.ant-checkbox:focus .ant-checkbox-inner {
  @apply border-gray-900
}

.ant-input-textarea-show-count::after {
  font-size: 12px;
}

.ant-modal-header {
  padding: 16px 11px;
}

.ant-modal-close .ant-modal-close-x {
  width: 36px;
}

.custom-switch {
  .ant-switch {
    width: 50px !important;
  }

  button.ant-switch {
    line-height: 19px;
    background-color: rgba(59, 59, 59, 0.8) !important;
    border: 2px solid rgba(59, 59, 59, 0.8) !important;

    .ant-switch-inner {
      color: white !important;
      font-weight: 600;
      margin-left: 20px;
    }

    .ant-switch-handle {
      left: 2px;
    }

    .ant-switch-handle::before {
      width: 14px;
      height: 14px;
      background-color: white;
    }
  }

  button.ant-switch.ant-switch-checked {

    background-color: white !important;
    border: 2px solid rgba(59, 59, 59, 0.8) !important;

    .ant-switch-inner {
      color: rgba(59, 59, 59, 0.8) !important;
      font-weight: 600;
      margin-left: 4px;
    }

    .ant-switch-handle {
      left: calc(100% - 17px);
    }

    .ant-switch-handle::before {
      width: 14px;
      height: 14px;
      background-color: rgba(59, 59, 59, 0.8);
    }
  }
}

.ciq-collapse {
  background-color: #ffffff !important;
  border: 0 !important;

  .ant-collapse-header {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-bottom: 0;
  }

  .ant-collapse-item {
    margin-bottom: 5px;
  }

  .ant-collapse-content {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }
}