@media print {
    body {
        background-color: white;
    }

    // Common Print css
    .hide-on-print {
        display: none;
    }

    nav.on-print {
        display: none;
    }

    .text-wrap-on-print {
        text-wrap: wrap;
    }

    .ag-layout-print svg {
        zoom: 90%;

        // TODO: need to refator the viz css for print and define it
        .SubmittalDiffV6_milestoneLabel__Yk1ut, // dev
        .SubmittalDiff_milestoneLabelWrapper__lTJk1, // qa
        .SubmittalDiff_milestoneLabel__4pbzX, // demo
        .SubmittalDiff_milestoneLabel__4pbzX // prod 
        {
            text-wrap: wrap;
        }
    }

    //start -- Risk report 
    .status-col {
        text-wrap: wrap;
        line-height: 1;
        margin-bottom: 8px;
    }

    .ag-cell {
        // font-size: 11px;
    }

    .ag-layout-print {

        .ag-cell-value,
        .ag-group-value,
        .ag-cell {
            .truncate {
                text-wrap: wrap;
                text-overflow: initial;
                line-height: 1 !important;
                line-break: anywhere;
                padding-top: 3px;
                white-space: unset;
                overflow: unset;
            }
        }

        .ag-cell-value {
            text-wrap: wrap;
            text-overflow: initial;
            line-break: anywhere;
            padding-top: 3px;
            // white-space: unset;
            overflow: hidden;
        }

        .ag-cell .ag-group-value a {
            line-height: normal;
            display: inline-block;
        }

        .colFirstValue,
        .colSecondValue {
            overflow: initial;
            text-overflow: initial;
            white-space: normal;
            text-wrap: wrap;
        }

        .colSecondValue {
            height: auto;
            line-height: normal;
            margin-top: 0;
            text-wrap: wrap;
            margin-bottom: 0;
        }

        .ag-cell .truncate.status-col {
            line-break: auto;
        }

        .ag-row:last-child {
            border-bottom: none;
        }
    }

    .ag-header-cell-text {
        text-wrap: wrap;
        text-overflow: initial;
        // line-height: 1;
    }

    .ag-root-wrapper.ag-layout-print .ag-row-odd {
        page-break-after: auto;
    }

    // Fix the background color print in pdf. it is a known bug in chrome
    // https://forum.jsreport.net/topic/1676/incorrect-colors-for-footer-content-template/3
    .viz-block {
        -webkit-print-color-adjust: exact;
    }

    .risk-report-page {
        display: inline-block;
        width: fit-content;

        .ag-layout-print svg:first-child {
            margin-bottom: 20px;
        }

        .ag-root-wrapper.ag-layout-print .ag-row-odd {
            page-break-after: always;
        }

        .ag-root-wrapper.ag-layout-print .ag-row-odd:last-child {
            page-break-after: auto;
        }

        .ag-cell div p.truncate.grow {
            margin-top: -10px;
        }

        .ag-row .ag-cell:nth-child(3),
        .ag-row .ag-cell:nth-child(6) {
            line-height: 1 !important;
        }

        .ag-row .ag-cell:nth-child(4) .status-col {
            line-break: anywhere;
        }

        .viz-block--small {
            margin-top: 3px;
        }

        .ag-row .ag-cell:nth-child(3) {
            display: flex;
            align-items: center;
        }
    }

    //End-- Risk report 
}