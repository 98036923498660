.project-dashboard {

    .hello-user-text {
        font-family: Inter;
        font-size: 26px;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: 0em;
        text-align: left;
        color: #3B3B3B

    }

    .ant-card-bordered {
        @apply border-gray-400;
    }

    .ant-card-head {
        @apply border-b-gray-400;
    }

    .ant-card-head, .ant-card-body {
        @apply px-3;
    }

    .summary-item {
        @apply w-full h-[70px] flex bg-white items-center border border-solid border-gray-400 rounded-sm;

        &.overdue-text {
            color: #FF662E
        }
    }
    
    .action-item {
        @apply w-full bg-white border border-solid border-gray-300 rounded-sm p-2 text-left;
    }

    .chart-card {
        @apply py-1 border border-gray-400 border-solid rounded-sm;
    }

    .date-label {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #3B3B3B
    }

    .doughnut-chart-container {
        @apply flex flex-col justify-center items-center relative gap-y-2;

        .doughnut-parent {
            @apply flex justify-center;
            height: 235px;
            max-height: 235px;
        }

        .total-count {
            @apply absolute font-medium text-center text-xs;
            top: 100px;
            color: #3B3B3B
        }

        .legend {
            @apply px-2;
            ul {
                @apply p-0 m-0 list-none grid grid-cols-2 gap-y-1.5 gap-x-3;
    
                li {
                    @apply flex items-start text-xs font-normal cursor-pointer;
    
                    span {
                        @apply rounded-full mr-1 mt-0.5;
                        min-width: 10px;
                        min-height: 10px;
                    }
                }
    
            }
    
        }
    }
}