
.schedule-versions{
    .ant-card-body {
        padding: 0px;
    }

    .ag-menu-option:hover {
      background-color: #e3e3e3;
        
    }
}