.feature-status-popover-content {
    .ant-picker {
        border: none;

        input {
            width: 0;
            height: 0;
        }
    }

    .ant-picker-dropdown {
        top: 0px !important;
        left: 0px !important;
    }

    .ant-picker-header {
        border-bottom: none;
    }

    .ant-picker-panel-container {
        box-shadow: none;
        margin-top: 0px;
        margin-left: 0px;

        .ant-picker-panel-focused {
            border-color: transparent;
        }
    }

    .ant-picker-date-panel .ant-picker-body {
        padding: 0px !important;
    }

    .ant-picker-content {
        width: 100% !important;
        height: 100% !important;
        table-layout: fixed;
        border-collapse: collapse;
    }

    .ant-picker-decade-panel,
    .ant-picker-year-panel,
    .ant-picker-quarter-panel,
    .ant-picker-month-panel,
    .ant-picker-week-panel,
    .ant-picker-date-panel,
    .ant-picker-time-panel {

        width: 100% !important;
    }

    .ant-modal-title {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        text-align: left;
        color: #3B3B3BCC;
    }

    .ant-picker-header-view {
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;

    }

    .ant-picker-header button {
        color: rgba(0, 0, 0, 1);
    }

}