@import '../../assets/scss/var.scss';

.summary-table {

    border-collapse: separate;

    thead {
        border-radius: 4px 4px 0px 0px;
        overflow: hidden;

        th {
            color: #888;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            padding: 7px 5px;
            background: #F7F6F4;
        }

        :first-child {
            border-top-left-radius: 4px;
        }

        :last-child {
            border-top-right-radius: 4px;
        }
    }

    td {
        border: 0.5px solid rgba(59, 59, 59, 0.20);
        background: rgba(59, 59, 59, 0.02);
        // padding: 5px;
    }

    .cell-with-padding,
    .button-with-padding {
        padding: 5px;
    }

    .text-danger {
        color: $date-block-delay-bg-color;
    }
}
