.bid-package-header {
    .label {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: right;
        color: #3B3B3BE5;
    }

}