.document-list-item{
    .doc-label{
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0em;
        text-align: left;
        color: #3B3B3B;
    }
}