.entity-title-panel-title {
    max-width: 320px;
    padding-right: 24px;
    position: relative;

    .ant-typography-edit {
        position: absolute;
        display: inline-block;
        right: 6px;
    }
}