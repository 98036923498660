.custom-date-picker {
  width: 600px !important;
}
.custom-date-picker .ant-modal-content {
  background-color: #fff !important;
}

.custom-date-picker .ant-picker {
  border: 0;
}

.custom-date-picker .ant-picker-range-separator,
.custom-date-picker .ant-picker-active-bar,
.custom-date-picker .ant-picker-suffix {
  display: none;
}

.custom-date-picker .ant-picker-panel-container {
  box-shadow: none;
  margin-top: -30px;
  margin-left: 12px;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.2);
}
.custom-date-picker .ant-picker-input {
  display: none;
}
.custom-date-picker .ant-modal-body {
  height: 285px;
}
.custom-date-picker .ant-modal-footer {
  padding-bottom: 20px;
  text-align: center;
  border-top: none;
}

.custom-date-picker .ant-picker-range {
  width: 100%;
}

.ant-picker-panel:nth-child(2) {
  margin-left: 10px;
}
.customRangePicker .ant-picker-dropdown-hidden {
  display: block !important;
}
.custom-date-picker .ant-picker-range-arrow {
  display: none !important;
}

.date-picker-with-icon .ant-picker-suffix {
  display: initial !important;
}

.ant-picker-range .ant-picker-input > input{
  text-align: center;
}