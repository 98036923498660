.upcoming-submittal {
    .ag-cell-wrapper.ag-row-group {
        @apply uppercase font-medium
    }
    .ag-details-row {
        @apply p-0
    }
    .ag-center-cols-container, .ag-center-cols-clipper {
        min-height: 0px !important;
    }
}