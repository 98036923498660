.module-settings {
    .ant-tabs-top>.ant-tabs-nav {
        height: 45px;
        margin-bottom: 0px;
        margin-top: 0px;
        margin-left: 10px;
    }

    &--tabs {

        .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
            padding: 8px 4px;
            white-space: break-spaces;
            text-align: left;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            text-shadow: none;
        }

        .ant-tabs-tab.ant-tabs-tab-active {
            color: #3B3B3B;
            background-color: #F0F0F0;
            font-weight: 500;
        }

        .ant-tabs-content-holder {
            height: calc(100vh - 160px);
        }

        input:disabled {
            color: #3B3B3BCC;
            background-color: #3B3B3B0D;
        }

        .ant-radio-disabled+span {
            color: #3B3B3BCC;
        }

        .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane,
        .ant-tabs-left>div>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
            padding-left: 0px;
        }

        .ant-input[disabled] {
            color: #3B3B3BCC;
            background-color: #3B3B3B0D;
        }

        .ant-input {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #3B3B3BCC;
        }

        .titles {
            font-family: Inter;
            font-size: 15px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #3B3B3BCC;
        }

        .info-label {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #3B3B3BCC;
        }

    }

}