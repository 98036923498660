.inline-edit-title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #3B3B3BCC;
}

.detail-header-paragraph {

    div.ant-typography,
    .ant-typography p {
        margin-bottom: 0px;
    }
}