.activity-grid-node {
    padding: 10px 6px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 1px solid #3B3B3B33; 
    position: relative; 

    @apply grid grid-cols-12 gap-1;

    i {
        opacity: 0.6;
    }
    &__title {
        background-color: rgba(59, 59, 59, 0.2);
        @apply text-black font-semibold
    }
    &__icon {
        @apply col-span-4 truncate
    }
    &__name {
        @apply col-span-4 truncate text-ellipsis justify-start
    }
    &__date {
        @apply justify-end col-span-2
    }
    &__toggle {
        @apply border-none mt-1 bg-transparent px-1
    }
    &__context-menu {
        @apply fixed;
        cursor: default;
        border: var(--ag-borders) var(--ag-border-color);
        background-color: var(--ag-control-panel-background-color);
        border-radius: var(--ag-card-radius);
        box-shadow: var(--ag-card-shadow);
        padding: var(--ag-grid-size);
        padding: 6px 0;
        z-index: 1;
        &-option {
            display: flex;
            align-items: center;
            line-height: var(--ag-icon-size);
            padding: calc(var(--ag-grid-size) + 2px) 0;
            padding-left: 10px;
            padding-right: calc(var(--ag-grid-size) * 2);
            cursor: pointer;
            & > svg {
                margin-right: 4px;
            }
            &:hover {
                background-color: #e3e3e3;
            }
        }
    }
}

.activity-grid {
    &--no-schedule {
        font-size: 14px;
    }

    &--header {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    &--overlay-loading {
        position: absolute;
        top: 40px;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;

        &>div {
            padding: 10px;
            background-color: rgb(255, 255, 255);
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 4px #3B3B3B33;
        }

    }

    &--version {
        background-color: #fff;
        padding: 8px;
        box-shadow: 0 0 4px #3b3b3b85;
        border-radius: 4px;

        &__option {
            font-size: 14px;
            padding: 4px 6px;
            cursor: pointer;

            &:hover {
                background-color: #3B3B3B33;
            }
        }
    }

    &--loading {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        &>div {
            padding: 10px;
            background-color: rgb(255, 255, 255);
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 4px #3B3B3B33;
        }
    }
    .dateHedderCell {
       .ag-header-cell-label {
           justify-content: right;
           margin-right: 6px;
        } 
    }
}

.activity-version-list {
    &--title {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 6px;
        border-bottom: 1px solid #3B3B3B33;
    }

    &__item {
        padding: 10px 6px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-bottom: 1px solid #3B3B3B33;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 4px;

        i {
            opacity: 0.6;
            margin-left: 6px;
        }
    }
}