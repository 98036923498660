.heading {
  font-size: var(--font-size-four);
  line-height: var(--line-height-four);
  letter-spacing: 0px;
  /* color: #00000080; */
  opacity: 1;
  margin-bottom: 9px;
  @apply text-neutral-600 font-semibold;
}

.duein-label {
  font-size: var(--font-size-four);
  line-height: var(--line-height-four);
  letter-spacing: 0px;
  margin-bottom: 9px;
}

.description {
  /* background-color: #0000000d; */
  text-align: left;
  font-size: var(--font-size-five);
  line-height: var(--line-height-five);
  letter-spacing: 0px;
  color: #000000fa;
  opacity: 1;
  height: 50px;
  @apply p-2.5;
}

.description.ant-input-disabled {
  @apply !text-gray-700;
}

/* .dependsOnRowData {
  height: 3.71vh;
  padding-left: 1.37vh;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 0.98vh;
  text-align: left;
  font: normal normal normal 1.56vh/1.95vh Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
} */

.actionItemCard {
  width: 100%;
  margin: 0px !important;
  position: absolute;
  bottom: -1%;
  margin-bottom: 0px !important;
  background-color: white;
  height: 14.5vh;
  padding: 1.95vh;
}

.subDetailsAction {
  text-align: left;
  font: normal normal normal 1.76vh/2.05vh Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.actionItemsCheckBox {
  text-align: left;
  font: normal normal normal 1.56vh/1.95vh Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.respondLink {
  text-align: left;
  font: normal normal normal 1.56vh/1.95vh Inter;
  letter-spacing: 0px;
  color: #cccccc !important;
  opacity: 1;
}

.footerLink {
  text-align: left;
  font: normal normal normal 1.56vh/1.95vh Inter;
  letter-spacing: 0px;
  color: #007aff !important;
  opacity: 1;
}

.text-red {
  color: red;
  padding-left: 2%;
}

/* .action-item-col {
  margin-bottom: 1.95vh;
  height: 2.05vh;
  padding: 0px;
} */
.NewButtonForOpenMOdal {
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: 25%;
}


.submittal-details .card-block.ant-card {
  @apply h-full flex flex-col;
}

.submittal-details .card-block.ant-card .ant-card-body {
  @apply grow;
}

.submittal-details .card-block,
.submittal-details .date-block-row {
  border: 1px solid rgba(59, 59, 59, 0.50);
}

.submittal-details .actionable .card-block {
  border: 2px solid rgba(59, 59, 59, 0.80) !important;
}

.submittal-details .readonly-collapse {
  border: 0;
}

.submittal-details .readonly-collapsible-panel {
  border: 1px solid rgba(59, 59, 59, 0.50) !important;

}

.submittal-details .readonly-collapsible-panel:first-child {
  margin-bottom: 10px;
}

.date-block-row[data-material="false"] .submittal-col {
  width: 100%;
}

.date-block-row[data-material="false"] .submittal-col-container {
  width: 100%;
}

.submittal--warning-message {
  color: rgb(255, 166, 0);
  border: 1px solid rgb(255, 166, 0);
  background-color: rgba(255, 166, 0, 0.2);
  border-radius: 4px;
  font-size: 10px;
  padding: 4px 10px;
}

.no-linking-item-found {
  height: 14rem;
  min-width: 300px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.no-linking-item-found--title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #3b3b3bb1
}

.workflow-document-prereview {
  display: flex;
  padding: 4px 6px;
  margin-bottom: 10px;
  color: #3B3B3B80;
}

.workflow-document-prereview .anticon {
  margin-top: 4px;
}

.workflow-document-prereview .anticon-delete {
  color: #3B3B3BCC;
}

.workflow-document-prereview .workflow-document-prereview--title {
  flex-grow: 1;
  font-size: 12px;
}

.workflow-document-upload {
  border: 0px solid transparent !important;
  background-color: transparent !important;
  color: #5399EB !important;
  padding: 1px !important;
}

.workflow-document-upload:hover {
  border: 0px solid transparent !important;
  background-color: transparent !important;
  color: #5399EB !important;
  border-bottom: 1px solid #5399EB !important;
  border-radius: 0 !important;
  padding: 1px !important;
}

.workflow-document-upload:disabled {
  color: #3B3B3B80 !important;
}

.workflow-document-upload:disabled:hover {
  border-bottom: 0px solid !important;
}

.add-more-btn {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  text-decoration: underline;
  text-underline-position: below;
}

.submittal-details .distribution-list-na {
  display: none;
}

.submittal-details .readonly-card,
.submittal-details .readonly-collapsible-panel {
  background-color: rgba(245, 245, 245, 1);
}

.submittal-details .readonly-collapsible-panel .card-block.readonly-card {
  border: 0 !important;
}

.submittal-details .readonly-card .distribution-list-input.hide-input .ant-select {
  display: none !important;
}

.submittal-details .readonly-card .distribution-list-input.hide-input .distribution-list-na {
  display: block;
}

.submittal-details .readonly-card .distribution-list-input * {
  cursor: default !important;
}

.submittal-details .readonly-card .distribution-list-input .ant-select-selector {
  border: 0 !important;
  max-height: 140px;
  overflow: auto;
}

.submittal-details .readonly-card .distribution-list-input .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.85);
}

.submittal-details .readonly-collapsible-panel .ant-collapse-content-box {
  background-color: rgba(245, 245, 245, 1);
}


.createSpecSectionHeader {
  background: #F7F6F4;
  border-bottom: 1px solid #3B3B3B4D
  
}

.createSpecSectionLbl {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B3B3BE5;
}