.container-home-page {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h1 {
  font-family: inter;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
}
