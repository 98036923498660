.bid-package-plan-panel {
    .border-type-1 {
        border-color: #000000;
        border-style: solid;
    }

    .border-type-2 {
        border-color: #3B3B3BCC;
        border-style: solid;
    }

    .bg-type-1 {
        background-color: #F7F6F4
    }

    .ant-picker-suffix {
        display: initial !important
    }

    .text-color-1 {
        color: #555555D9
    }

    .text-color-2 {
        color: #3B3B3B;
    }

    .text-color-3 {
        color: #3B3B3B99;
    }

    .separator {
        border-bottom: 1px solid #D9D9D9CC;
    }

    .segment-selector {
        .ant-segmented-item-label {
            font-size: 12px;
            line-height: 25px;
        }
    }
}