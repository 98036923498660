.submittal-tooltip {
  max-width: 300px;
  ;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 0px 11px hsla(0, 0%, 0%, 0.073);
  opacity: 1;
  padding: 10px;
}

.submittal-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.submittalId {
  color: #0000007f;
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  opacity: 1;
}

.submittalName {
  color: #000000;
  text-align: left;
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  opacity: 1;
}

.submittalStatus {
  color: #000000FA;
  text-align: left;
  font: normal normal normal 12px/15px Inter;
  letter-spacing: 0px;
  opacity: 1;
}

.dueBy {
  color: #0000007f;
  text-align: left;
  font: normal normal normal 12px/15px Inter;
  letter-spacing: 0px;
  opacity: 1;
}

.customtooltip {
  @apply flex-col justify-center bg-white max-w-lg p-2 border border-solid border-gray-300 text-gray-600 space-y-1 rounded;
}

.customheadertooltip {
  @apply flex-col justify-center bg-white max-w-lg p-2 border border-solid border-gray-300 text-gray-700 space-y-1 rounded;
}