.view-dependencies-modal {
    .ant-modal-body{
        margin: 0;
        padding: 0;
    }
    .ant-modal-title{
        padding-left: 25px;
    }
    .ant-modal-close{
        // color: #3B3B3B;
        padding-right: 15px;
    }
}