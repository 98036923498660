.bulk-edit-submittals {
  .panel {
    border: 1px solid #d9d9d9;
  }

  .panel-header {
    border-bottom: 1px solid #d9d9d9;
    padding: 12px 16px;
  }
}

.submittal-modal-footer {
  display: "flex";
  justify-content: "flex-end";
  padding-bottom: "10px";
}
