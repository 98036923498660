@tailwind utilities;

$text-color-1: #5A5A5A;
$text-color-3: #020617;
$text-color-4: #000000D9;
$text-color-5: #3B3B3B;
$text-color-6: #00000073;
$text-color-7: #000000E0;
$text-color-8: #555555D9;
$text-color-9: #616161;
$text-color-10: #8C8C8C;

$border-color-1: #737373CC;
$border-color-2: #CCCCCC;
$border-color-3: #BDBDBD;
$border-color-4: #D5D5D5;
$border-color-5: #E6E6E6;

$background-color-1: #DBDBDB;
$background-color-2: #F7F6F4;

.material-planning-page {


    .text-color-1 {
        color: $text-color-1;
    }

    .text-color-3 {
        color: $text-color-3;
    }

    .text-color-4 {
        color: $text-color-4;
    }

    .text-color-5 {
        color: $text-color-5;
    }

    .text-color-6 {
        color: $text-color-6;
    }

    .text-color-7 {
        color: $text-color-7;
    }

    .text-color-9 {
        color: $text-color-9;
    }

    .text-color-10 {
        color: $text-color-10;
    }

    .border-color-1 {
        border-color: $border-color-1;
    }

    .border-color-2 {
        border-color: $border-color-2;
    }

    .border-color-3 {
        border-color: $border-color-3;
    }

    .border-color-4 {
        border-color: $border-color-4;
    }

    .border-color-5 {
        border-color: $border-color-5;
    }

    .background-color-1 {
        background-color: $background-color-1;
    }

    .collapse-label {
        display: none;
    }

    .ant-collapse-header {
        position: sticky !important;
        top: 0 !important;
        background-color: $background-color-2;
        z-index: 10;
    }


    .ant-collapse-item-active {
        .expand-label {
            display: none;
        }

        .collapse-label {
            display: block;
        }
    }

    .ant-collapse-expand-icon {
        .ant-collapse-arrow {
            top: auto !important;
            bottom: 16.5px !important;
        }
    }

    @layer utilities {

        .material-details-section {
            &.vertical-shadow {
                box-shadow:
                    inset 0 6px 6px -4px rgba(0, 0, 0, 0.15),
                    inset 0 -6px 6px -4px rgba(0, 0, 0, 0.15);
            }

            &.no-vertical-shadow {
                box-shadow: none !important;
            }
        }

    }

    @keyframes fadeInOut {
        0% {
            opacity: 0;
        }
    
        100% {
            opacity: 1;
        }
    }
    
    .material-content {
        @screen md {
            animation: none;
        }
    
        animation: fadeInOut 0.4s ease-in-out;
    }

}

.material-list-drawer {
    .ant-drawer-body {
        padding: 0 !important;
    }

    .ant-drawer-content-wrapper {
        max-width: 70% !important;
    }
}

.request-details-drawer {

    .text-color-1 {
        color: $text-color-1;
    }

    .text-color-8 {
        color: $text-color-8;
    }

    .background-color-2 {
        background-color: $background-color-2;
    }

    .ant-drawer-header-title {
        display: block;
    }
}

.material-details-drawer {
    .text-color-1 {
        color: $text-color-1;
    }
}